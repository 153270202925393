import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './events-dialog.css';
import '../../common.css';
import {Modal} from "react-bootstrap";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

import EventsList from './events-list'

class DialogEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            events: [],
            isLoaded: false,
            error: null,
            wasUpdated: false
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleEventAdd = this.handleEventAdd.bind(this);
        this.handleEventRemove = this.handleEventRemove.bind(this);
        this.handleEventUpdate = this.handleEventUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    loadEvents() {
        const url = `${process.env.REACT_APP_PROJECTS}/${this.state.projectId}/events`;
        console.log(url);
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                const events = [];
                response.data.events.forEach(event => {
                    events.push({
                        id: event.id,
                        text: event.event_text,
                        date: event.event_date,
                        country: event.event_country,
                        isNew: false
                    })
                })
                this.setState({
                    events: events,
                    isLoaded: true
                })
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadEvents();
    }

    handleClose() {
        if (this.state.wasUpdated) {
            this.props.onDataUpdated();
        }
        this.props.onClose();
    }

    handleEventAdd(event) {
        this.setState(prevState => ({
            events: [...prevState.events, event]
        }));
    }

    handleEventRemove(id) {
        this.setState(prevState => ({
            events: prevState.events.filter(event => event.id !== id)
        }));
    }

    handleEventUpdate(id, text, date, country) {
        this.setState({
            events: this.state.events.map (event => {
                if (event.id === id) {
                    event.text = text
                    event.date = date
                    event.country = country
                    event.isNew = false
                }

                return event
            })
        })
    }

    handleUpdate() {
        this.setState({
            wasUpdated: true
        });
    }

    render() {
        console.log('Events dialog render');
        return (
            <Modal show={true} onHide={this.handleClose} size={'lg'}>
                <Modal.Header>
                    <Modal.Title>Events management</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <EventsList
                        token={this.state.token}
                        projectId={this.state.projectId}
                        key={this.state.events.length}
                        events={this.state.events}
                        onAdd={this.handleEventAdd}
                        onRemove={this.handleEventRemove}
                        onUpdate={this.handleEventUpdate}
                        onDataUpdated={this.handleUpdate}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={this.handleClose}>Cancel</button>
                    {/*<button className='btn btn-primary' onClick={this.handleSaveChanges}>Save changes</button>*/}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DialogEvents