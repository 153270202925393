import React from "react";
import axios from "axios";
import {Store} from "react-notifications-component";
import {Dropdown} from "react-bootstrap";

class SettingsAppNamesRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            appNameId: props.appNameId,
            appName: props.appName,
            projectId: -1,
            projectName: props.projectName,
            projects: props.projects,
            isDirty: false
        }

        this.handleProjectChanged = this.handleProjectChanged.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
    }


    handleProjectChanged(event) {
        this.setState({
            text: event.target.value
        })
    }



    handleConfirmClick() {
        const notificationSuccess = {
            title: 'Create/update event',
            message: 'Success',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const notificationError = {
            ...notificationSuccess,
            message: 'Error',
            type: 'danger'
        }

        const url = process.env.REACT_APP_NOT_FOUND;
        const requestConfig = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        let requestBody = {
            app_name: this.state.appName,
            project_id: this.state.projectId,
        }

        console.log(requestBody);

        axios.post(url, requestBody, requestConfig)
            .then(result => {
                if (result.data.status === 'success') {
                    Store.addNotification(notificationSuccess)
                    this.setState({
                        isDirty: false
                    })
                    this.props.onDataUpdated();
                } else {
                    console.log(result.data.data);
                    Store.addNotification({
                        ...notificationError,
                        message: result.data.data,
                    })
                }
            })
            .catch(error => {
                console.log(error)
                Store.addNotification({
                    ...notificationError,
                    message: 'Catched error',
                })
            })
    }


    handleProjectSelected(eventKey, eventTitle) {
        console.log(eventKey)
        console.log(eventTitle)
        this.setState({
            projectId: eventKey,
            projectName: eventTitle,
        })
    }

    render() {
        return (
            <div className={'flex event-row'}>
                <input
                    readOnly
                    key={this.state.appNameid}
                    type='text'
                    defaultValue={this.state.appName}
                    className='form-control event-form-input w-50'
                />
                &emsp;
                <Dropdown
                    onSelect={(eventKey, event) =>
                        this.handleProjectSelected(
                            event.target.id,
                            event.target.title
                        )}>

                    <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic">
                        {this.state.projectName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {this.state.projects.map(project =>
                            <Dropdown.Item
                                as="button"
                                key={project.id}
                                id={project.id}
                                title={project.name}
                            >{project.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                &emsp;
                <button
                    className='btn btn-success'
                    onClick={this.handleConfirmClick}
                    disabled={(this.state.projectId === -1) && (this.state.isDirty === false)}>
                    <i className="fas fa-check event-form-input"/>
                </button>
            </div>
        );
    }
}

export default SettingsAppNamesRow