import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Main from './main/main';
import Project from './project/project';
import Login from "./login/login";
import Register from "./register/register";
import {Redirect} from "react-router";
import {ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Settings from "./settings/settings";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: localStorage.getItem('fromDate') ? new Date(parseInt(localStorage.getItem('fromDate'))) : new Date(),
            toDate: localStorage.getItem('toDate') ? new Date(parseInt(localStorage.getItem('toDate'))) : new Date(),
            fromDateRoi1: localStorage.getItem('fromDateRoi1') ? new Date(parseInt(localStorage.getItem('fromDateRoi1'))) : new Date(),
            toDateRoi1: localStorage.getItem('toDateRoi1') ? new Date(parseInt(localStorage.getItem('toDateRoi1'))) : new Date(),
            fromDateRoi2: localStorage.getItem('fromDateRoi2') ? new Date(parseInt(localStorage.getItem('fromDateRoi2'))) : new Date(),
            toDateRoi2: localStorage.getItem('toDateRoi2') ? new Date(parseInt(localStorage.getItem('toDateRoi2'))) : new Date(),

            token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleRoi1DateChange = this.handleRoi1DateChange.bind(this);
        this.handleRoi2DateChange = this.handleRoi2DateChange.bind(this);
        this.handleTokenUpdate = this.handleTokenUpdate.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleDateChange(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        }, () => {
            localStorage.setItem('fromDate', fromDate.getTime());
            localStorage.setItem('toDate', toDate.getTime());
        })
    }

    handleRoi1DateChange(fromDate, toDate) {
        this.setState({
            fromDateRoi1: fromDate,
            toDateRoi1: toDate
        }, () => {
            localStorage.setItem('fromDateRoi1', fromDate.getTime());
            localStorage.setItem('toDateRoi1', toDate.getTime());
        })
    }

    handleRoi2DateChange(fromDate, toDate) {
        this.setState({
            fromDateRoi2: fromDate,
            toDateRoi2: toDate
        }, () => {
            localStorage.setItem('fromDateRoi2', fromDate.getTime());
            localStorage.setItem('toDateRoi2', toDate.getTime());
        })
    }

    handleTokenUpdate(token) {
        this.setState({
            token: token
        }, () => {
            localStorage.setItem('token', token);
        })
    }

    handleLogout() {
        this.setState({
            token: ''
        }, () => {
            localStorage.setItem('token', '');
        })
    }

    render() {
        return (
            <div>
                <Router>
                    <div className='content-area'>
                        <Switch>
                            <Route exact path='/'>
                                {this.state.token !== '' ?
                                    <Main
                                        token={this.state.token}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        onDateChange={this.handleDateChange}
                                        onLogout={this.handleLogout}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/projects/:id'>
                                {this.state.token !== '' ?
                                    <Project
                                        token={this.state.token}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        fromDateRoi1={this.state.fromDateRoi1}
                                        toDateRoi1={this.state.toDateRoi1}
                                        fromDateRoi2={this.state.fromDateRoi2}
                                        toDateRoi2={this.state.toDateRoi2}
                                        onDateChange={this.handleDateChange}
                                        onRoi1DateChange={this.handleRoi1DateChange}
                                        onRoi2DateChange={this.handleRoi2DateChange}
                                    />
                                    :
                                    <Redirect to='/login'/>
                                }
                            </Route>

                            <Route path='/settings'>
                                {this.state.token !== '' ?
                                    <Settings
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login'/>
                                }
                            </Route>

                            <Route path='/login'>
                                <Login onTokenUpdate={this.handleTokenUpdate}/>
                            </Route>

                            <Route path='/register'>
                                <Register/>
                            </Route>

                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

ReactDOM.render(
    <div>
        <ReactNotifications />
        <App/>
    </div>,
    document.getElementById('root')
);