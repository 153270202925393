import React from "react";
import axios from "axios";
import EventsNotifications from "../events-history/events-notifications";
import CountriesTable from "../countries-table/countries-table";
import Card from "../../common-components/data-card/data-card";
import {Form} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const currency = process.env.REACT_APP_CURRENCY;

function CardsPanel(props) {
    return (
        <div className='flex cards-panel'>
            <Card description='Чистыми' value={props.data.profit + currency} colorstyle='green'/>
            <Card description='Доходы с рекламы' value={props.data.income + currency} colorstyle='green-light'/>
            <Card description='Расходы' value={props.data.expenses + currency} colorstyle='red'/>
            <Card description='Цена конверсии' value={props.data.convertionPrice + currency} colorstyle='yellow'/>
            <Card description='CPM' value={props.data.cpm + currency} colorstyle='blue'/>
            <Card description='Подписки' value={props.data.subscriptions + currency} colorstyle='green-light'/>
        </div>
    );
}

function Chart(props) {
    let incomes = [];
    let subsIncomes = [];
    let convertionPrices = [];
    let cpms = [];
    let profits = [];
    let expenses = []
    let dates = [];

    for (let i = 0; i < props.data.length; i++) {
        incomes.push(props.data[i].income);
        subsIncomes.push(props.data[i].subs);
        convertionPrices.push(props.data[i].convertionPrice);
        cpms.push(props.data[i].cpm);
        profits.push(props.data[i].profit);
        expenses.push(props.data[i].expenses);
        dates.push(props.data[i].date);
    }

    let labels = [];
    let lastY = 2;
    let lastDate = '2000-01-01';

    if(props.enableAnnotations){
        for (let i = 0; i < props.events.length; i++) {
            const date = props.events[i].date

            const diff = Math.abs(new Date(date) - new Date(lastDate)) / 1000 / 60 / 60 / 24;

            let baseY = profits[dates.indexOf(date)];

            if (diff < 3){
                if (baseY < lastY) {
                    lastY = lastY * -1;
                }

                if (baseY < 100){
                    lastY = baseY + (lastY * 0.5);
                }else{
                    lastY = baseY + (lastY * 2);
                }

                if (lastY < 0){
                    lastY = 0
                }
            }else{
                lastY = baseY;
            }
            lastDate = date;

            const label = {
                text: props.events[i].country + ": " + props.events[i].text,
                padding: 5,
                point: {
                    x: dates.indexOf(date),
                    y: lastY,
                    xAxis: 0,
                    yAxis: 0
                }
            }
            labels.push(label);

            lastY = baseY;
        }
    }

    const options = {
        title: {
            text: 'Статистика'
        },
        xAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                enabled: true,
                formatter: function () {
                    return dates[this.value];
                }
            }
        },
        yAxis: [{title: ''}, {title: ''}],
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Доходы с рекламы',
                data: incomes,
                yAxis: 0
            },
            {
                type: 'column',
                name: 'Подписки',
                data: subsIncomes,
                yAxis: 0
            },
            {
                name: 'Чистыми',
                data: profits,
                yAxis: 0,
                stacking: null,
            },
            {
                name: 'Цена конверсии',
                data: convertionPrices,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'CPM',
                data: cpms,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'Расходы',
                data: expenses,
                stacking: null,
                yAxis: 0
            }
        ],

        annotations: labels.map(label => ({
            labelOptions: {
                backgroundColor: 'rgba(255, 255, 255, 0.55)',
                verticalAlign: 'bottom',
                allowOverlap: true,
                overflow: 'justify'
            },
            labels: label
        }))
    }
    return (
        <div className='chart'>
            <div className="col-12 d-flex flex-row justify-content-end">
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="События"
                        defaultChecked={props.enableAnnotations}
                        onChange={props.handleEnableAnnotationsChange}
                    />
                </Form>
            </div>

            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}

class FilterableChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            projectId: props.projectId,
            fromDate: props.fromDate,
            toDate: props.toDate,
            fromDateRoi1: props.fromDateRoi1,
            toDateRoi1: props.toDateRoi1,
            fromDateRoi2: props.fromDateRoi2,
            toDateRoi2: props.toDateRoi2,
            data: props.data,
            projectData: {},
            isLoaded: false,
            error: null,
            events: props.events,
            countries: props.countries,
            filterTitle: '',
            enableAnnotations: true,
        }

        this.handleSelectCountry = this.handleSelectCountry.bind(this);
        this.loadCountryDataByTitle = this.loadCountryDataByTitle.bind(this);
        this.handleEnableAnnotationsChange = this.handleEnableAnnotationsChange.bind(this);
        this.closeEventNotification = this.closeEventNotification.bind(this);
    }

    loadProjectData() {
        const url = process.env.REACT_APP_PROJECTS + '/' + this.state.projectId;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            },
            params: {
                'from_date': this.state.fromDate,
                'to_date': this.state.toDate,
                'from_date_roi1': this.state.fromDateRoi1,
                'to_date_roi1': this.state.toDateRoi1,
                'from_date_roi2': this.state.fromDateRoi2,
                'to_date_roi2': this.state.toDateRoi2,
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response);
                const data = [];
                response.data.chart_data.forEach(item => {
                    data.push({
                        date: item.date,
                        income: item.income,
                        convertionPrice: item.convertionPrice,
                        cpm: item.cpm,
                        profit: item.profit,
                        expenses: item.expenses,
                        subs: 0
                    })
                });
                const subsKeys = Object.keys(response.data.subs_data);
                subsKeys.forEach(key => {
                    let exists = false;
                    data.forEach(item => {
                        if (response.data.subs_data[key].report_date === item.date) {
                            exists = true;
                            item.subs = response.data.subs_data[key].income_calculated;
                        }
                    })
                    if (!exists) {
                        if (response.data.subs_data[key].income_calculated > 0) {
                            data.push({
                                date: response.data.subs_data[key].report_date,
                                income: 0,
                                convertionPrice: 0,
                                cpm: 0,
                                profit: 0,
                                expenses: 0,
                                subs: response.data.subs_data[key].income_calculated
                            })
                        }
                    }
                })
                data.sort((d1, d2) => {
                    return d1.date > d2.date ? 1 : -1
                })
                // noinspection JSUnresolvedVariable
                this.setState({
                    isLoaded: true,
                    projectData: {
                        title: response.data.name,
                        appName: response.data.app_name,
                        profit: response.data.total_profit,
                        income: response.data.total_income,
                        convertionPrice: response.data.total_convertion_price,
                        cpm: response.data.total_cpm,
                        proceeds: response.data.total_proceeds,
                        expenses: response.data.total_expenses,
                        subscriptions: response.data.total_subs
                    },
                    data: data,
                    countries: response.data.table_data,
                    events: response.data.event_data.map(event => {
                        return {
                            id: event.id,
                            text: event.event_text,
                            country: event.event_country,
                            date: event.event_date,
                            viewed: event.event_viewed,
                            showStats: event.showStats,
                            stats: event.stats,

                        }
                    })
                });
                this.props.onUpdate(response.data.name, response.data.app_name);
            })
            .catch(error => this.setState({
                isLoaded: true,
                error: error
            }));
    }

    loadCountryDataByTitle(title) {
        const data = [];
        const url = process.env.REACT_APP_PROJECTS + '/' + this.state.projectId + '/countries/' + title;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            },
            params: {
                from_date: this.state.fromDate,
                to_date: this.state.toDate
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response);
                response.data.chart_data.forEach(item => {
                    data.push({
                        date: item.report_date,
                        income: item.income_calculated,
                        convertionPrice: item.conversion_costs,
                        cpm: item.ecpm_calculated,
                        profit: item.proceeds,
                        expenses: item.ad_expenses_calculated,
                        subs: 0
                    })
                })
                this.setState({
                    data: data,
                    isLoaded: true
                })
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadProjectData();
    }

    handleSelectCountry(title) {
        this.setState({
            filterTitle: title
        });
        if (title !== '') {
            this.loadCountryDataByTitle(title);
        } else {
            this.loadProjectData();
        }
    }

    handleEnableAnnotationsChange(event){
        console.log(event.target.checked)
        this.setState({
            enableAnnotations: event.target.checked
        })
    }

    closeEventNotification(event){
        const eventId = parseInt(event.target.id);
        const projectId = this.state.projectId;
        const url = process.env.REACT_APP_PROJECTS + '/' + projectId + '/events/' + eventId + '/viewed';
        const requestConfig = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.post(url, [], requestConfig)
            .then(result => {
                if (result.data.status === 'success') {
                    const events = this.state.events;
                    const updEvents = events.map(event => {
                        if (event.id === eventId){
                            event.viewed = true;
                        }
                        return event
                    });

                    this.setState({
                        events: updEvents
                    })
                } else {
                    console.log(result.data.message);
                }
            })
            .catch(error => {
                console.log(error.response.data.message);
            })
    }

    render() {
        if (this.state.error) {
            return (
                <div>{this.state.error.message}</div>
            );
        } else if (!this.state.isLoaded) {
            return (
                <div>Loading...</div>
            );
        } else {
            return (
                <div>
                    <EventsNotifications
                        events={this.state.events}
                        currency={currency}
                        closeEvent={this.closeEventNotification}
                    />
                    <CardsPanel data={this.state.projectData}/>
                    <Chart
                        data={this.state.data}
                        events={this.state.events}
                        enableAnnotations={this.state.enableAnnotations}
                        handleEnableAnnotationsChange={this.handleEnableAnnotationsChange}/>
                    <CountriesTable countries={this.state.countries} onSelect={this.handleSelectCountry}/>
                </div>
            );
        }
    }
}

export default FilterableChart