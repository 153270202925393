import React from "react";
import axios from "axios";
import {Store} from "react-notifications-component";
import dateFormat from "dateformat";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";

registerLocale('ru', ru)

class EventRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            eventId: props.eventId,
            projectId: props.projectId,
            text: props.text,
            country: props.country,
            oldCountry: props.country,
            oldText: props.text,
            oldDate: dateFormat(props.date, 'yyyy-mm-dd'),
            date: dateFormat(props.date, 'yyyy-mm-dd'),
            isNew: props.isNew,
        }

        this.handleRemove = this.handleRemove.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    handleRemove() {
        if (this.state.isNew) {
            this.props.onRemove(this.state.eventId);
            return;
        }

        const notificationSuccess = {
            title: 'Delete event',
            message: 'Success',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const notificationError = {
            ...notificationSuccess,
            message: 'Error',
            type: 'danger'
        }

        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        const url = process.env.REACT_APP_PROJECTS + '/' + this.state.projectId + '/events/' + this.state.eventId;
        axios.delete(url, config)
            .then(result => {
                if (result.data.status === 'success') {
                    Store.addNotification(notificationSuccess)
                    this.props.onRemove(this.state.eventId);
                    this.props.onDataUpdated();
                } else {
                    Store.addNotification({
                        ...notificationError,
                        message: result.data
                    })
                }
            })
            .catch(error => {
                Store.addNotification({
                    ...notificationError,
                    message: error.response.data
                })
            })
    }

    handleTextChange(event) {
        this.setState({
            text: event.target.value
        })
    }

    handleCountryChange(event) {
        this.setState({
            country: event.target.value
        })
    }

    handleDateChange(date) {
        const dateString = dateFormat(date, 'yyyy-mm-dd');
        this.setState({
            date: dateString
        });
    }

    handleConfirmClick() {
        const notificationSuccess = {
            title: 'Create/update event',
            message: 'Success',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const notificationError = {
            ...notificationSuccess,
            message: 'Error',
            type: 'danger'
        }

        const url = process.env.REACT_APP_PROJECTS + '/' + this.state.projectId + '/events';
        const requestConfig = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        let requestBody = {
            event_text: this.state.text,
            event_date: this.state.date,
            event_country: this.state.country,
        }

        if (!this.state.isNew) {
            requestBody = {
                ...requestBody,
                event_id: this.state.eventId
            }
        }

        console.log(requestBody);

        axios.post(url, requestBody, requestConfig)
            .then(result => {
                if (result.data.status === 'success') {
                    Store.addNotification(notificationSuccess)
                    this.props.onUpdate(
                        this.state.eventId,
                        this.state.text,
                        this.state.date,
                        this.state.country
                    );
                    this.props.onDataUpdated();

                    this.setState({
                        isNew: false,
                        text: this.state.text,
                        date: this.state.date,
                        country: this.state.country,
                        oldCountry: this.state.country,
                        oldText: this.state.text,
                        oldDate: this.state.date,
                    })
                } else {
                    console.log(result.data.message);
                    Store.addNotification({
                        ...notificationError,
                        message: result.data.message,
                    })
                }
            })
            .catch(error => {
                console.log(error.response.data.message);
                Store.addNotification({
                    ...notificationError,
                    message: error.response.data.message,
                })
            })
    }

    render() {
        return (
            <div className='flex event-row'>
                <DatePicker
                    className='form-control event-form-input'
                    selected={Date.parse(this.state.date)}
                    locale='ru'
                    dateFormat="dd.MM.yyyy"
                    onChange={(date) => {
                        this.handleDateChange(date)
                    }}/>
                &emsp;
                <input type='text' defaultValue={this.state.country} className='form-control event-form-input'
                       placeholder='Country' onChange={this.handleCountryChange}/>
                &emsp;
                <input type='text' defaultValue={this.state.text} className='form-control event-form-input'
                       placeholder='Text' onChange={this.handleTextChange}/>
                &emsp;
                <button className='btn btn-success' onClick={this.handleConfirmClick}
                        disabled={(this.state.text === this.state.oldText &&
                            this.state.date === this.state.oldDate  &&
                            this.state.country === this.state.oldCountry) || (this.state.text === '' && this.state.country === '')}>
                    <i className="fas fa-check event-form-input"/>
                </button>
                &emsp;
                <button className='btn btn-danger' onClick={this.handleRemove}><i
                    className="fas fa-times event-form-input"/>
                </button>
            </div>
        );
    }
}

export default EventRow
