import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './project.css';
import './../common.css';
import DateRangeSelector from '../common-components/date-range-selector/date-range-selector.js';
import DialogEvents from "./events-dialog/events-dialog.js";
import DialogSubs from './subs-dialog/subs-dialog.js';
import DialogDeleteProject from "./delete-dialog/delete-dialog.js";
import DialogUploadFile from "./upload-dialog/upload-dialog.js";
import Highcharts from 'highcharts'
import AnnotationsFactory from "highcharts/modules/annotations";
import {withRouter} from "react-router";
import "react-bootstrap";
import FilterableChart from "./chart/FilterableChart";
import UniversalNotification from "../universal-notification/universal-notification";
import {Button} from "react-bootstrap";

AnnotationsFactory(Highcharts);

class ProjectMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            projectTitle: props.projectTitle,
            showingMenu: false,
            showingDeleteProjectDialog: false,
            showingParamsDialog: false
        }

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleParamsClick = this.handleParamsClick.bind(this);
        this.handleHideDialogs = this.handleHideDialogs.bind(this);
    }

    handleMenuClick() {
        this.setState(prevState => ({
            showingMenu: !prevState.showingMenu
        }))
    }

    handleDeleteClick() {
        this.setState({
            showingDeleteProjectDialog: true
        });
    }

    handleParamsClick() {
        this.setState({
            showingParamsDialog: true
        })
    }

    handleHideDialogs() {
        this.setState({
            showingDeleteProjectDialog: false,
            showingParamsDialog: false
        });
    }

    render() {
        return (
            <div className="dropdown">
                <i className="icon-settings fas fa-cog" onClick={this.handleMenuClick}/>
                {this.state.showingMenu ?
                    <div className='menu-holder'>
                        <div className='menu'>
                            <ul>
                                <li className='list-group-item'>
                                    <button className='btn btn-danger' onClick={this.handleDeleteClick}>Delete project</button>
                                    {this.state.showingDeleteProjectDialog ?
                                        <DialogDeleteProject
                                            token={this.state.token}
                                            projectId={this.state.projectId}
                                            projectTitle={this.state.projectTitle}
                                            onClose={this.handleHideDialogs}/>
                                        : null}
                                </li>
                                {/*<li className='list-group-item'>*/}
                                {/*    <button className='btn btn-outline-secondary' onClick={this.handleParamsClick}>Parameters</button>*/}
                                {/*    {this.state.showingParamsDialog ?*/}
                                {/*        <DialogParams*/}
                                {/*            token={this.state.token}*/}
                                {/*            projectId={this.state.projectId}*/}
                                {/*            projectTitle={this.state.projectTitle}*/}
                                {/*            onClose={this.handleHideDialogs}/>*/}
                                {/*        : null}*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}

class ProjectHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            title: props.title,
            appName: props.appName,
            fromDateOverall: props.fromDateOverall,
            toDateOverall: props.toDateOverall,
            fromDateRoi1: props.fromDateRoi1,
            toDateRoi1: props.toDateRoi1,
            fromDateRoi2: props.fromDateRoi2,
            toDateRoi2: props.toDateRoi2
        }
        this.handleOverallDateRangeChange = this.handleOverallDateRangeChange.bind(this);
        this.handleRoi1DateRangeChange = this.handleRoi1DateRangeChange.bind(this);
        this.handleRoi2DateRangeChange = this.handleRoi2DateRangeChange.bind(this);
    }

    handleOverallDateRangeChange(fromDate, toDate) {
        this.setState({
            fromDateOverall: fromDate,
            toDateOverall: toDate
        });
        this.props.onOverallDateRangePicked(fromDate, toDate);
    }

    handleRoi1DateRangeChange(fromDate, toDate) {
        this.setState({
            fromDateRoi1: fromDate,
            toDateRoi1: toDate
        });
        this.props.onRoi1DateRangePicked(fromDate, toDate);
    }

    handleRoi2DateRangeChange(fromDate, toDate) {
        this.setState({
            fromDateRoi2: fromDate,
            toDateRoi2: toDate
        });
        this.props.onRoi2DateRangePicked(fromDate, toDate);
    }

    render() {
        return (
            <div className={'head-container'}>
                <div className="row">
                    <div className='flex spaced-between project-header'>
                        <div className='flex centered-vertical minified'>
                            <Link to='/'>
                                <i className='arrow-back fas fa-long-arrow-alt-left'/>
                            </Link>
                            &emsp;
                            <h2>{this.state.title}</h2>
                        </div>

                        <div>
                            <p>App name:<br/><b>{this.state.appName}</b></p>
                        </div>

                        <div className='flex minified'>
                            <ProjectMenu
                                token={this.state.token}
                                projectId={this.state.projectId}
                                projectTitle={this.state.title}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className='flex minified spaced-between'>
                            <div className="flex-column">
                                <label className={'text-secondary'}>Общее</label>
                                <DateRangeSelector
                                    fromDate={this.state.fromDateOverall}
                                    toDate={this.state.toDateOverall}
                                    gravityRight={false}
                                    onChange={this.handleOverallDateRangeChange}/>
                            </div>
                            <div className={"flex-column"}>
                                <label className={'text-secondary'}>ROI #1</label>
                                <DateRangeSelector
                                    fromDate={this.state.fromDateRoi1}
                                    toDate={this.state.toDateRoi1}
                                    gravityRight={true}
                                    onChange={this.handleRoi1DateRangeChange}/>
                            </div>
                            <div className={"flex-column"}>
                                <label className={'text-secondary'}>ROI #2</label>
                                <DateRangeSelector
                                    fromDate={this.state.fromDateRoi2}
                                    toDate={this.state.toDateRoi2}
                                    gravityRight={true}
                                    onChange={this.handleRoi2DateRangeChange}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

class ButtonsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            subs: props.subs,
            showUploadDialog: false,
            showEventsDialog: false,
            showSubsDialog: false
        }

        this.handleDataClick = this.handleDataClick.bind(this);
        this.handleEventsClick = this.handleEventsClick.bind(this);
        this.handleSubsClick = this.handleSubsClick.bind(this);
        this.handleHideDialogs = this.handleHideDialogs.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleDataClick() {
        this.setState({
            showUploadDialog: true
        });
    }

    handleEventsClick() {
        this.setState({
            showEventsDialog: true
        })
    }

    handleSubsClick() {
        this.setState({
            showSubsDialog: true
        });
    }

    handleHideDialogs() {
        this.setState({
            showUploadDialog: false,
            showEventsDialog: false,
            showSubsDialog: false
        });
    }

    handleUpdate() {
        this.props.onDataUpdated();
    }

    render() {
        return (
            <div className='flex buttons-panel minified'>
                <button className='btn btn-outline-success' onClick={this.handleDataClick}>Отчеты</button>
                <button className='btn btn-outline-warning button-project'
                        onClick={this.handleSubsClick}>Подписки
                </button>
                <button className='btn btn-outline-primary button-project' onClick={this.handleEventsClick}>События
                </button>

                {this.state.showUploadDialog ?
                    <DialogUploadFile
                        token={this.state.token}
                        projectId={this.state.projectId}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={() => {
                            this.handleUpdate()
                            this.props.updateNotif()
                        }}/>
                    : null
                }
                {this.state.showEventsDialog ?
                    <DialogEvents
                        token={this.state.token}
                        projectId={this.state.projectId}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={this.handleUpdate}/>
                    : null
                }
                {this.state.showSubsDialog ?
                    <DialogSubs
                        token={this.state.token}
                        projectId={this.state.projectId}
                        subs={this.state.subs}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={this.handleUpdate}/>
                    : null
                }
            </div>
        );
    }

}

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            title: '',
            appName: '',
            projectId: this.props.match.params.id,
            fromDate: props.fromDate,
            toDate: props.toDate,
            fromDateRoi1: props.fromDateRoi1,
            toDateRoi1: props.toDateRoi1,
            fromDateRoi2: props.fromDateRoi2,
            toDateRoi2: props.toDateRoi2,
            showUniversalNotifications: false,
            notifUpdateNeeded: false,
            data: {},
            chart_data: [],
            table_data: [],
            updateData: false
        }

        this.handleOverallDateRangeChanged = this.handleOverallDateRangeChanged.bind(this);
        this.handleRoi1DateRangeChanged = this.handleRoi1DateRangeChanged.bind(this);
        this.handleRoi2DateRangeChanged = this.handleRoi2DateRangeChanged.bind(this);
        this.handleProjectUpdate = this.handleProjectUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleToggleUniversalNotifications = this.handleToggleUniversalNotifications.bind(this);
        this.handleNotifUpdateNeeded = this.handleNotifUpdateNeeded.bind(this);
        this.universalNotificationsDataLoaded = this.universalNotificationsDataLoaded.bind(this);
    }

    handleOverallDateRangeChanged(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        this.props.onDateChange(fromDate, toDate);
    }

    handleRoi1DateRangeChanged(fromDate, toDate) {
        this.setState({
            fromDateRoi1: fromDate,
            toDateRoi1: toDate
        });
        this.props.onRoi1DateChange(fromDate, toDate);
    }

    handleRoi2DateRangeChanged(fromDate, toDate) {
        this.setState({
            fromDateRoi2: fromDate,
            toDateRoi2: toDate
        });
        this.props.onRoi2DateChange(fromDate, toDate);
    }

    handleProjectUpdate(title, appName) {
        this.setState({
            title: title,
            appName: appName,
        });
    }

    handleUpdate() {
        this.setState(prevState => ({
            updateData: !prevState.updateData
        }));
    }

    handleToggleUniversalNotifications(){
        this.setState({
            showUniversalNotifications: !this.state.showUniversalNotifications,
            notifUpdateNeeded: false
        })
    }

    handleNotifUpdateNeeded() {
        this.setState( {
            notifUpdateNeeded: true
        });
    }

    universalNotificationsDataLoaded() {
        this.setState( {
            notifUpdateNeeded: false
        });
    }

    render() {
        const fromDateFormat = this.state.fromDate.getFullYear() + "-" + ("0" + (this.state.fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.fromDate.getDate()).slice(-2);
        const toDateFormat = this.state.toDate.getFullYear() + "-" + ("0" + (this.state.toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.toDate.getDate()).slice(-2);

        const fromDateFormatRoi1 = this.state.fromDateRoi1.getFullYear() + "-" + ("0" + (this.state.fromDateRoi1.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.fromDateRoi1.getDate()).slice(-2);
        const toDateFormatRoi1 = this.state.toDateRoi1.getFullYear() + "-" + ("0" + (this.state.toDateRoi1.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.toDateRoi1.getDate()).slice(-2);

        const fromDateFormatRoi2 = this.state.fromDateRoi2.getFullYear() + "-" + ("0" + (this.state.fromDateRoi2.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.fromDateRoi2.getDate()).slice(-2);
        const toDateFormatRoi2 = this.state.toDateRoi2.getFullYear() + "-" + ("0" + (this.state.toDateRoi2.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.toDateRoi2.getDate()).slice(-2);

        console.log('Render project');

        return (
            <div>
                <ProjectHeader
                    token={this.state.token}
                    projectId={this.state.projectId}
                    key={this.state.title + this.state.updateData}
                    title={this.state.title}
                    appName={this.state.appName}
                    fromDateOverall={this.state.fromDate}
                    toDateOverall={this.state.toDate}
                    fromDateRoi1={this.state.fromDateRoi1}
                    toDateRoi1={this.state.toDateRoi1}
                    fromDateRoi2={this.state.fromDateRoi2}
                    toDateRoi2={this.state.toDateRoi2}
                    onOverallDateRangePicked={this.handleOverallDateRangeChanged}
                    onRoi1DateRangePicked={this.handleRoi1DateRangeChanged}
                    onRoi2DateRangePicked={this.handleRoi2DateRangeChanged}/>

                <ButtonsPanel
                    token={this.state.token}
                    projectId={this.state.projectId}
                    subs={this.state.subscriptions}
                    onDataUpdated={this.handleUpdate}
                    updateNotif={this.handleNotifUpdateNeeded}/>

                <FilterableChart
                    token={this.state.token}
                    key={fromDateFormat +
                        toDateFormat +
                        fromDateFormatRoi1 +
                        toDateFormatRoi1 +
                        fromDateFormatRoi2 +
                        toDateFormatRoi2 +
                        this.state.updateData
                    }
                    projectId={this.state.projectId}
                    data={this.state.chart_data}
                    events={[]}
                    countries={this.state.table_data}
                    fromDate={fromDateFormat}
                    toDate={toDateFormat}
                    fromDateRoi1={fromDateFormatRoi1}
                    toDateRoi1={toDateFormatRoi1}
                    fromDateRoi2={fromDateFormatRoi2}
                    toDateRoi2={toDateFormatRoi2}
                    onUpdate={this.handleProjectUpdate}/>

                    <UniversalNotification
                        key={"universalNotification"}
                        token={this.state.token}
                        show={this.state.showUniversalNotifications}
                        updateNeeded={this.state.notifUpdateNeeded}
                        onClose={this.handleToggleUniversalNotifications}
                        onDataLoaded={this.universalNotificationsDataLoaded}
                    />
                    <div className="position-fixed end-0 bottom-0">
                        <Button
                            className="mb-3 me-3"
                            onClick={this.handleToggleUniversalNotifications}
                        >
                            <i className="fas fa-envelope"/>
                        </Button>
                    </div>
            </div>
        );
    }
}

export default withRouter(Project)