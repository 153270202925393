import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import axios from "axios";
import SettingsAppNamesRow from "./settings-app-names-row";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            selectedProjects:[],
            notFound: [],
            projects: []
        }

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate(){
        this.loadData();
    }


    loadData() {
        const url = process.env.REACT_APP_NOT_FOUND;
        const config = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response.data.data)
                const notFound = [];
                const projects = [];
                response.data.data.not_found.forEach(item => {
                    notFound.push({
                        id: item.id,
                        appName: item.app_name
                    })
                })

                response.data.data.projects.forEach(item => {
                    projects.push({
                        id: item.id,
                        name: item.name,
                    })
                })
                this.setState({
                    notFound: notFound,
                    projects: projects
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div className={'container'}>
                <div className="row">
                    <div className='flex centered-vertical minified'>
                        <Link to='/'>
                            <i className='arrow-back fas fa-long-arrow-alt-left'/>
                        </Link>
                        &emsp;
                        <h2>Настройки отчетов</h2>
                    </div>
                </div>
                <div className="row mt-5">
                    <h3>Связать приложение с проектом</h3>
                </div>
                <div className="row">
                    {
                        this.state.notFound.map(item =>
                            <SettingsAppNamesRow
                            token={this.state.token}
                            appNameId={item.id}
                            appName={item.appName}
                            projects={this.state.projects}
                            projectName={"Выбрать проект"}
                            onDataUpdated={this.handleUpdate}
                            />
                        )
                    }
                </div>

            </div>
        );
    }
}

export default withRouter(Settings)