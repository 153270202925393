import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './date-range-selector.css';
import '../../common.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePicker} from 'react-date-range';

class DateRangeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: props.fromDate,
            toDate: props.toDate,
            gravityRight: props.gravityRight,
            showDateRangePicker: false
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeDateClick = this.handleChangeDateClick.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
    }

    handleSelect(range) {
        this.setState({
            fromDate: range.selection.startDate,
            toDate: range.selection.endDate
        });
        this.props.onChange(range.selection.startDate, range.selection.endDate);
    }

    handleChangeDateClick() {
        this.setState(prevState => ({
            showDateRangePicker: !prevState.showDateRangePicker
        }))
    }

    handleCollapseClick() {
        this.setState({
            showDateRangePicker: false
        })
    }

    render() {
        const selectionRange = {
            startDate: this.state.fromDate,
            endDate: this.state.toDate,
            key: 'selection',
        }

        const dateOptions = {
            day: 'numeric',
            year: 'numeric',
            month: 'long'
        }

        const formattedDateRange =
            <div className='flex'>
                {this.state.fromDate.toLocaleDateString('ru-RU', dateOptions)}
                &emsp;-&emsp;
                {this.state.toDate.toLocaleDateString('ru-RU', dateOptions)}
            </div>

        return (
            <div className='datepicker-holder'>
                <div className='flex date-range centered-vertical spaced-between' onClick={this.handleChangeDateClick}>
                    {formattedDateRange}
                    &emsp;
                    {this.state.showDateRangePicker ?
                        <i className="date-picker-icon fas fa-caret-up"/>
                        :
                        <i className="date-picker-icon fas fa-caret-down"/>
                    }
                </div>
                {this.state.showDateRangePicker ?
                    <div
                        className='datepicker border rounded'
                        style={this.state.gravityRight === true ? {right: 0} : {left: 0}}
                    >
                        <DateRangePicker
                            ranges={[selectionRange]}
                            weekStartsOn={1}
                            onChange={this.handleSelect}
                        />
                    </div>
                    : null}
            </div>
        );
    }
}

export default DateRangeSelector