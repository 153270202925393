import Alert from 'react-bootstrap/Alert';
import React from "react";
import './event-notifications.css';
import {CloseButton, Dropdown} from "react-bootstrap";

class EventsNotifications extends React.Component  {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            events: props.events,
            projectId: props.projectId,
            currency: props.currency,
            showDropdown: false,
            showDropdownOnRender: true,
        }

        this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
    }

    handleDropdownToggle(newToggle){
        this.setState({
            showDropdownOnRender: false,
            showDropdown: newToggle
        });
    }

    render() {
        let notifications = [];
        let viewedNotifications = [];
        let showDropdown = this.state.showDropdown

        this.state.events.forEach((event) => {
            let status = 'success';
            if (event.stats.before_profits > event.stats.after_profits){
                status = 'danger'
            }else if (event.stats.before_profits === event.stats.after_profits){
                status = 'warning'
            }

            if (event.showStats === true) {
                if (event.viewed === '0'){
                    if (showDropdown === false && this.state.showDropdownOnRender){
                        showDropdown = true
                    }
                    notifications = [
                        ...notifications,
                        (<Alert key={event.id} variant={status}>
                            <div className="flex flex-row justify-content-between">
                                <div>
                                    ({event.date}) {event.country}: {event.text}<br/>
                                    {event.stats.before_profits}{this.state.currency} => {event.stats.after_profits}{this.state.currency}
                                </div>
                                <CloseButton id={event.id} onClick={this.props.closeEvent}/>
                            </div>
                        </Alert>)
                    ]
                }else{
                    viewedNotifications = [
                        ...viewedNotifications,
                        (<Alert key={event.id} variant={status}>
                            <div className="flex flex-row justify-content-between">
                                <div>
                                    ({event.date}) {event.country}: {event.text}<br/>
                                    {event.stats.before_profits}{this.state.currency} => {event.stats.after_profits}{this.state.currency}
                                </div>
                            </div>
                        </Alert>)
                    ]
                }
            }
        });

        return (
            <Dropdown
                show={showDropdown}
                onToggle={(newToggle) => {this.handleDropdownToggle(newToggle)}}
                className={'mt-4'}>
                <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic">
                    История событий
                </Dropdown.Toggle>

                <Dropdown.Menu className={'overflow-auto event-dialog p-2'}>
                    {notifications}
                    <h6>Просмотрено</h6>
                    {viewedNotifications}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default EventsNotifications;