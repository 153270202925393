import React from "react";
import EventRow from './events-row'

function convertDateToString(date) {
    return date.getFullYear() + "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("0" + date.getDate()).slice(-2);
}

class EventsList extends React.Component {
    constructor(props) {
        super(props);

        let maxId = 0;
        if (props.events.length > 0) {
            for (let i = 0; i < props.events.length; i++) {
                if (props.events[i].id > maxId) {
                    maxId = props.events[i].id;
                }
            }
        }

        this.state = {
            token: props.token,
            projectId: props.projectId,
            events: props.events,
            lastId: maxId
        }

        this.handleCreateEventClick = this.handleCreateEventClick.bind(this);
        this.handleRemoveEventClick = this.handleRemoveEventClick.bind(this);
        this.handleUpdateEventClick = this.handleUpdateEventClick.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleCreateEventClick() {
        const event = {
            id: this.state.lastId + 1,
            date: convertDateToString(new Date()),
            text: '',
            isNew: true
        }
        this.props.onAdd(event);
    }

    handleRemoveEventClick(id) {
        this.props.onRemove(id);
    }

    handleUpdateEventClick(id, text, date, country) {
        this.props.onUpdate(id, text, date, country)
    }

    handleUpdate() {
        this.props.onDataUpdated();
    }

    render() {

        return (
            <div>
                <div className='centered'>
                    <button className='btn btn-outline-success' onClick={this.handleCreateEventClick}>Create event
                    </button>
                </div>
                {this.state.events.map(event =>
                    <EventRow
                        token={this.state.token}
                        projectId={this.state.projectId}
                        eventId={event.id}
                        key={event.id}
                        date={event.date}
                        country={event.country}
                        text={event.text}
                        isNew={event.isNew}
                        onRemove={this.handleRemoveEventClick}
                        onUpdate={this.handleUpdateEventClick}
                        onDataUpdated={this.handleUpdate}
                    />
                )}
            </div>
        );
    }
}

export default EventsList
